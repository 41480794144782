@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .gradient-b-t {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  .gradient-b-t-h {
    /* background-color: black; */
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.5)
    );
    /*
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 90%,
      rgba(0, 0, 0, 1) 100%
    );
    */
  }

  .gradient-yellow-to-bottom {
    background-image: linear-gradient(
      to bottom,
      rgb(255, 205, 0) 0%,
      rgb(207, 154, 19) 100%
    );
  }
  .gradient-yellow-to-right {
    background-image: linear-gradient(
      to right,
      rgb(255, 205, 0) 0%,
      rgb(207, 154, 19) 100%
    );
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Oswald", sans-serif;
}

input:focus,
input:focus-within {
  outline: none;
}

input[type="checkbox"] + label::before {
  content: "\f00c" !important;
  left: -15px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transform: scale(0) !important;
  transition: 0.3s ease-in-out;
}

input[type="checkbox"] + label::after {
  left: -18px;
}

input[type="checkbox"].privacy-checkbox + label::after,
input[type="checkbox"].privacy-checkbox + label::before {
  top: 3.2rem;
}

input[type="checkbox"]:checked + label::before {
  transform: scale(1) rotate(-5deg) translate(-1px, -1.5px) !important;
}

.privacy-policy__content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.privacy-policy__content::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: transparent;
}

.privacy-policy__content::-webkit-scrollbar-thumb {
  background: rgb(111, 111, 111, 0.7);
  border-radius: 100vh;
}

.privacy-policy__content ul li {
  margin-bottom: 0.5rem;
}

.account-settings input + label {
  color: rgb(111, 111, 111, 0.7);
}

.account-settings input:focus + label,
.account-settings input:focus-within + label {
  color: #25ab75 !important;
}

.password-checklist li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.offscreen {
  position: absolute;
  left: -99999;
}

.errmsg,
.successmsg {
  padding: 0.5rem;
  font-weight: bold;
  border-radius: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
}

.successmsg {
  background-color: #96edca;
  color: #025602;
}

.drop_area .break-line::before,
.drop_area .break-line::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 2px;
  background-color: #000;
}

.drop_area .break-line::before {
  left: 1.8rem;
}

.drop_area .break-line::after {
  right: 1.8rem;
}

.react-datepicker__navigation--years-upcoming,
.react-datepicker__navigation--years-previous {
  position: relative;
}

.react-datepicker__navigation--years-upcoming::after,
.react-datepicker__navigation--years-previous::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 10px;
}

.react-datepicker__navigation--years-upcoming::after {
  border-color: transparent transparent #000 transparent;
}

.react-datepicker__navigation--years-previous::after {
  border-color: #000 transparent transparent transparent;
}

.multiselect-container,
.multiselect-container *,
.multiselect-container::before,
.multiselect-container::after,
.multiselect-container *::before,
.multiselect-container *::after {
  border-color: transparent !important;
}

.css-1v50px9-control {
  border: none !important;
}

.multiselect-container .search-wrapper {
  border: none;
}

.multiselect-container .search-wrapper .searchBox::placeholder {
  color: rgb(111 111 111 / var(--tw-text-opacity));
}

.multiselect-container {
  background-color: rgb(27 27 27 / var(--tw-bg-opacity));
}

.multiselect-container .optionListContainer {
  right: 0;
  background-color: rgb(27 27 27 / var(--tw-bg-opacity));
}

.multiselect-container .optionListContainer .highlightOption,
.multiselect-container .optionListContainer .option:hover,
.multiselect-container .search-wrapper .chip {
  background-color: rgb(37 171 117 / var(--tw-text-opacity));
}

tr,
td,
th {
  border: 1px solid white;
  padding: 5px;
}

.privacy-policy {
  font-family: Arial, sans-serif !important;
  padding: 1em;
  font-size: 1.2rem;
}

.privacy-policy p b {
  display: inline-block;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.privacy-policy h2 {
  font-family: inherit !important;
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
